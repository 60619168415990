import React, { ReactElement } from 'react'

import Layout from '../components/layout'

import InsuranceLogo from '@/components/insurance-logo'

// markup
const IndexPage = (): ReactElement => {
  return (
    <Layout>
      <h1>Forsikring</h1>
      <p>
        Hos Kiropraktor Akutten kan du bruke alle forsikringer. Vi har avtale med Nordic Netcare (NNC), IF forsikring,
        Storebrand, Gjensidige, Sparebank 1, Tryg og Vertikal Helse. Om ikke ditt forsikringsselskap står her så kan du
        allikevel bruke den hos oss.
      </p>

      <p>Hos Kiropraktor Akutten er du alltid garantert time på dagen.</p>
      <InsuranceLogo />
    </Layout>
  )
}

export default IndexPage

import React, { ReactElement } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const useInsuranceLogo = () => {
  const data = useStaticQuery(graphql`
    query InsuranceLogo {
      allFile(filter: { relativeDirectory: { eq: "insurances" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, height: 80)
            }
          }
        }
      }
    }
  `)
  return data.allFile.edges
}

const InsuranceLogo = (): ReactElement => {
  const data = useInsuranceLogo()

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 pb-8 mt-8">
      {data.map((x, idx) => (
        <div key={idx} className="flex justify-center px-2">
          <GatsbyImage
            image={x.node.childImageSharp.gatsbyImageData}
            alt=""
            className="my-0"
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '50% 50%'
            }}
          />
          {/* <Img
            fixed={x.node.childImageSharp.fixed}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '50% 50%'
            }}
          /> */}
        </div>
      ))}
    </div>
  )
}

export default InsuranceLogo
